<template>
    <q-dialog v-model="departAdds" persistent @hide='closeadddepart' position="top">
        <q-card style="min-width: 550px">
          <q-card-section>
            <div class="text-h6">指定医院</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div class='departtop'>
              <q-form ref="appointdepart" greedy class="q-gutter-md add">
                <!-- 省份选择 -->
                <selectp @provincechange='provincechange' class='add' :options='addqueryDatas.province' :model.sync='addqueryDatas.provinceid' :label='"请选择省份*"' />
                <!-- 城市选择 -->
                <selectc @citychange='citychange' class='add' :options='addqueryDatas.city' :model.sync='addqueryDatas.cityId' :label='"请选择城市*"'/>
                <!-- 医院选择 -->
                <selecth class='add' :options='addqueryDatas.hosp' :model.sync='addqueryDatas.hospid' :label='"请选择医院*"'/>
                <!-- <q-input 
                  disable
                  style='margin-top: 0;' 
                  class="add" 
                  :dense='true' 
                  outlined  
                  v-model="appointdata.comboName" 
                  label="套餐名称" 
                  lazy-rules 
                  :rules="[required.kong]"
                />
                <q-input 
                  style='margin-top: 0;' 
                  type="number"
                  class="add" 
                  :dense='true' 
                  outlined  
                  v-model="addqueryDatas.comboAmt" 
                  label="套餐金额" 
                  lazy-rules 
                  :rules="[required.limit]"
                /> -->
              </q-form>
            </div>
            <!-- <div class='departtop'>
              <q-input outlined :dense="true" v-model="departDesc" label="请输入科室描述" />
            </div> -->
          </q-card-section>

          <q-card-actions align="right">
            <q-btn class="kong" flat label="取消" color="primary" v-close-popup />
            <q-btn label="指定" color="primary" @click='okadd'/>
          </q-card-actions>
        </q-card>
      </q-dialog>
</template>
<script>
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { getprovince,getcity,gethosplist,getdepart,getcombo } from '@/api/components/getCity.js'; // 获取城市接口


export default {
    props:{
        departAdd: { //控制指定医院
            type: Boolean,
            required: true,
        },
        addqueryData: { //控制指定医院
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            required: {
                kong: ()=>{},
                comboName: val => val && val.trim().length > 0 || '套餐名称不能为空',
                comboNamelength: val => val && val.trim().length <= 20 || '套餐名称长度不能超过20个字符',
                comboDesc: val => val && val.trim().length > 0 || '套餐描述不能为空',
                comboDesclength: val => val && val.trim().length <=80 || '套餐描述长度不能超过80个字符',
                appointAmt: val => val && val.length > 0 || '套餐金额不能为空',
                limit: val=> val && val>0 && val<= 999999.99 || '套餐金额必须在0~999999.99之间'
            },

        }
    },
    components:{
        selectp,
        selectc,
        selecth,
    },
    created(){
    },
    computed:{
        departAdds:{
            get(){
                return this.departAdd
            },
            set(v){
                this.$emit('update:departAdd',v)
            }
        },
        addqueryDatas:{
            get(){
                return this.addqueryData
            },
            set(v){
                this.$emit('update:addqueryData',v)
            }
        },
    },
    methods:{
        provincechange(){
            this.addqueryDatas.cityId=''
            this.addqueryDatas.city=[]
            this.addqueryDatas.hospid=''
            this.addqueryDatas.hosp=[]
            console.log(this.addqueryDatas.provinceid);
            getcity(this.addqueryDatas.city,this.addqueryDatas.provinceid.id)
        },
            // 城市变化时获取医院列表
        citychange(){
            this.addqueryDatas.hosp=[]
            this.addqueryDatas.hospid=''
            gethosplist(this.addqueryDatas.hosp,this.addqueryDatas.cityId.id) //获取医院信息用于检索
            console.log(this.addqueryDatas.hosp);
        },
        // 点击绑定时
        okadd(){
            this.$emit('okadd');
        },
        //弹窗关闭时
        closeadddepart(){
            this.$emit('closeadddepart')
        }
        
    },
}
</script>
<style lang="less" scoped>

</style>
