<template>
  <div id="Combo" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="基础数据管理" icon="iconfont icon-ziyuan143" />
      <q-breadcrumbs-el label="套餐管理" icon="iconfont icon-navicon-pgtcgl" to="combo/list" />
    </q-breadcrumbs>
    <!-- 总卡片区 -->
    <div class="combobox" id="combobox">
      <!-- 搜索卡片区 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="searchbox" id="searchbox">
              <!-- 搜索框 -->
              <div class="search">
                    <q-input class='operation' :dense='true' outlined  v-model="queryData.comboName" label="套餐名称">
                      <template v-if="queryData.comboName" v-slot:append>
                        <q-icon name="close" @click.stop="queryData.comboName = ''" class="cursor-pointer" style="font-size: 16px;"/>
                      </template>
                    </q-input>
                    <el-button  
                      :disabled='Permissionslist.filter(item=>item==="combo.list").length>0?false:true'
                      size='medium' 
                      @click='search'
                    >
                      搜索
                    </el-button>
                    <el-button
                    :disabled='Permissionslist.filter(item=>item==="combo.list").length>0?false:true'
                    size='medium'
                    @click='reset'
                    >
                      重置
                    </el-button>
              </div>
              <!-- 增查区 -->
              <div class="search">
                  <el-button
                  :disabled='Permissionslist.filter(item=>item==="combo.add").length>0?false:true'
                  size='medium'
                  @click='addconfirm = true'>
                    新增套餐
                  </el-button>
              </div> 
          </div>
        </q-card-section>
      </q-card>
      <!-- 列表区域 -->
      <q-card class="my-card">
        <q-card-section>
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          ref="multipleTable"
          :data="combolist"
          tooltip-effect="dark"
          :max-height="tableHeight"
          style="width: 100%"
          >
          <template slot="empty">
            {{nodatetype}}
          </template>
          <el-table-column align='center' prop="comboName" label="套餐名称"></el-table-column>
          <el-table-column align='center' show-overflow-tooltip prop="comboDesc" label="套餐描述"></el-table-column>
          <el-table-column align='center' prop="comboAmt" label="套餐价格" ></el-table-column>
          <el-table-column align='center' prop="returnedAmt" label="回款金额" ></el-table-column>
          <el-table-column align='center' prop="createTime" label="创建时间" min-width='151px'></el-table-column>
          <el-table-column align='center' label="操作" width="200" fixed="right">
            <template slot-scope="anniu">
              <q-btn
                :disable='Permissionslist.filter(item=>item==="combo.edit").length>0?false:true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-Edit" 
                @click="editCombo2(anniu.row)"
                >
                </q-btn>
                <q-btn
                :disable='Permissionslist.filter(item=>item==="combo.delete").length>0?false:true'
                size="sm" 
                color="red-6" 
                icon="iconfont icon-ashbin" 
                @click="delCombo(anniu.row)"
                >
                </q-btn>
            </template>
          </el-table-column>
          
        </el-table>
          <!-- 分页组件 -->
        <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getreconList()' @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>
    </div>
    <!-- 新增弹窗 -->
    <el-dialog :visible.sync="addconfirm" width="35%" title="新增套餐" :close-on-click-modal='false' @close="adddialogclose">
      <comboadd
        ref='comboadd'
        :addqueryData.sync='addqueryData'
        @addconfirmclose='adddialogclose'
        @addcombosuccess='getcombolist'
      >
      </comboadd>
    </el-dialog>  
     <!-- 编辑弹窗 -->
    <!-- <el-dialog :visible.sync="editconfirm" width="35%" title="编辑套餐" :close-on-click-modal='false' @close="editdialogclose">
      <comboedit
        ref='comboedit'
        :editData.sync='editData'
        @editclose='editdialogclose'
        @editcombosuccess='getcombolist'
      >
      </comboedit>
    </el-dialog>   -->
     <!-- 测试编辑弹窗 -->
    <el-dialog v-if="editconfirm2" :visible.sync="editconfirm2" width="65%" title="编辑套餐" :close-on-click-modal='false'  @close="editdialogclose2">
      <comboedit2
        ref='comboedit2'
        :editData.sync='editData'
        :addqueryData.sync='editqueryData'
        :addAppointData.sync='AppointDatas'
        :addShieldData.sync='ShieldDatas'
        @editconfirmclose='editdialogclose2'
        @editcombosuccess='getcombolist'
      >
      </comboedit2>
    </el-dialog>  
  </div>
  
</template>

<script>
import comboadd from '@/components/Combo/AddCombo.vue' // 添加套餐组件
// import comboedit from '@/components/Combo/editCombo.vue' // 添加套餐组件
import comboedit2 from '@/components/Combo/editCombo2.vue' // 编辑套餐组件
import { makeDebounce } from "@/utils/debounce.js";

import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import { provinceList,cityList,hospList,departList } from '@/api/City.js'; // 获取城市接口
// import { getprovince,getcity,gethosplist } from '@/api/components/getCity.js'; // 获取城市接口
import { comboList,comboCheck,comboDelete,searchhosp } from '@/api/Combo.js'; // 套餐增删改查接口
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度

import { mapState } from 'vuex'
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getcombolist()
  },
  data() {
    return {
        province:[], //省
        city:[], // 市
        hosplist:[], // 医院
        depart:[], // 科室

        tableHeight:0, //列表盒子最大高度

        ordervisible: false, //控制loading
        nodatetype:'', //无内容时显示
        // 列表头部信息
        // columns: [
        //   {
        //     name: 'comboName',
        //     required: true,
        //     label: '套餐名称',
        //     align: 'left',
        //     field: row => row.comboName,
        //     format: val => `${val}`,
        //     headerClasses: 'my-special-class'
        //   },
        //   // { name: 'comboName',align: 'center', label: '套餐名称', field: 'comboName',headerClasses: 'my-special-class'},
        //   { name: 'comboDesc',align: 'center', label: '套餐描述', field: 'comboDesc',headerClasses: 'my-special-class'},
        //   { name: 'comboAmt',align: 'center', label: '套餐价格', field: 'comboAmt',headerClasses: 'my-special-class'},
        //   { name: 'createTime',align: 'center', label: '创建时间', field: 'createTime',headerClasses: 'my-special-class'},
        //   // { name: 'state',align: 'center', label: '状态', field: 'state'},
        //   { name: 'operation',align: 'center', label: '操作', field: 'operation',headerClasses: 'my-special-class'}
        // ],
        combolist:[], //所有套餐数据
        
        provincevale: null, // 省份选择id
        queryData:{ // element分页信息
          comboName:'',
          page: 1,
          pageSize: 10
        },
        total:0, //总条数
        // pagination: {//quasar分页设置 
        //   page: 1,
        //   rowsPerPage: 20, //设置默认是显示多少条,
        // },

        addconfirm: false, // 控制新增窗口
        addqueryData:{
          comboName:'',
          comboDesc:'',
          comboAmt:'',
          returnedAmt:'', //#回款金额 必填  小数点后保留2位
          urgentAmt:'', //#加急金额 必填  小数点后保留2位
        },

        editconfirm: false, // 控制修改窗口
        editData:{}, //修改套餐所需信息

        editconfirm2: false, // 控制修改窗口
        editData2:{}, //修改套餐所需基础信息

        // 自定义金额模块所需信息
        editqueryData:{
          comboAmt:'', //订单金额
          returnedAmt:'', //#回款金额 必填  小数点后保留2位 
          allhosp:[], //储存医院
          hospid:'', //医院 .id
          cityId:'', //城市 .id
          provinceid:'', //省份 .id
          province:[], // 指定医院时 省份数据
          city:[], // 指定医院时 城市数据
          hosp: [], // 指定医院时医院数据
          chaxun: '', // 医院查询
        },
        // 指定医院模块所需信息
        AppointDatas:{
          allhosp:[], //储存指定医院列表信息医院
          hospid:'', //医院 .id
          cityId:'', //城市 .id
          provinceid:'', //省份 .id
          province:[], // 指定医院时 省份数据
          city:[], // 指定医院时 城市数据
          hosp: [], // 指定医院时医院数据
          chaxun: '', // 医院查询
        },
        // 屏蔽医院所需信息
        ShieldDatas:{
          allhosp:[], //储存指定医院列表信息医院
          hospid:'', //医院 .id
          cityId:'', //城市 .id
          provinceid:'', //省份 .id
          province:[], // 指定医院时 省份数据
          city:[], // 指定医院时 城市数据
          hosp: [], // 指定医院时医院数据
          chaxun: '', // 医院查询
        },
      };
  },
  components:{
    elementPagination,
    comboadd,
    // comboedit,
    comboedit2
  },
  computed:{
    ...mapState([
      'Permissionslist' // 权限词典
    ])
  },
  watch:{
  },
  methods: {
    // 套餐列表
    getcombolist(){
      this.nodatetype=''
      this.ordervisible = true
      setTimeout(async() =>{
        const { data:res } = await comboList({
          comboName: this.queryData.comboName,
          page: this.queryData.page,
          pageSize:  this.queryData.pageSize,
        })
        console.log(res);
        if(res.code ===20000){
          this.combolist = res.data.combos
          this.total = res.data.totals * 1
        }

        let height = document.getElementById('searchbox').offsetHeight + 85
        console.log(height);
        this.tableHeight = getHeight('combobox',height)

        this.ordervisible = false
        this.nodatetype='暂无数据'
      },500)
    },
  
    // 点击搜索
    search:makeDebounce(function(){
      this.queryData.page = 1
      this.getcombolist()
    },200),
    // 点击重置
    reset:makeDebounce(function(){
      this.queryData.page = 1
      this.queryData.comboName=''
      this.queryData.cityId=null
      this.queryData.hospId=null
      this.provincevale=null
      this.city=[]
      this.hosplist=[]
      this.getcombolist()
    },200),

    //打开测试编辑框
    editCombo2:makeDebounce(async function(row) {
      console.log(row);
      const { data: res } = await comboCheck({
        comboId:row.id,
      })
      console.log('套餐详细',res);
      if(res.code ===20000){
        this.editData = res.data.combo
        const { data: hospdata } = await searchhosp({comboId: row.id})
        console.log(hospdata);
        //放置 获取该套餐已指定医院的
        this.editqueryData.allhosp = hospdata.data.comboCityHospMergeVos
        // 储存套餐金额
        this.editqueryData.comboAmt = this.editData.comboAmt
        // 储存套餐回款金额 returnedAmt
        this.editqueryData.returnedAmt = this.editData.returnedAmt
        console.log(this.editqueryData.allhosp);
        this.editconfirm2 = true
      }
    }),
    // 编辑对话框关闭触发
    adddialogclose(){
      this.editqueryData.departName=''
      this.editqueryData.provinceid =''
      this.editqueryData.cityId =''
      this.editqueryData.city =[]
      this.editqueryData.province =[]
      this.addconfirm =false
      this.$refs.comboadd.$refs.myForm.resetValidation();
    },

    // 点击修改
    editCombo:makeDebounce(async function(row) {
      const { data: res } = await comboCheck({
        comboId:row.comboId,
        cityId: row.cityId,
        hospId: row.hospId,
      })
      console.log(res);
      if(res.code ===20000){
        console.log(row);

        this.editData = res.data.combo
        this.editconfirm = true
      }
    }),
    //点击删除
    async delCombo(row){
      console.log(row);
      this.$q.dialog({
        title: '删除',
        message: `你确定要删除套餐: ${row.comboName} 吗`,
        ok: { label: '确定',color: 'red'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const { data: res } = await comboDelete({
              comboId:row.id,
            })
            if(res.code===20000){
              this.getcombolist()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
    },
    // 添加对话框关闭触发
    adddialogclose(){
      this.addqueryData.comboName=''
      this.addqueryData.comboDesc=''
      this.addqueryData.comboAmt=''
      this.addqueryData.returnedAmt=''
      this.addqueryData.urgentAmt=''
      this.addconfirm =false
      this.$refs.comboadd.$refs.myForm.resetValidation();
    },
    // 测试修改弹窗关闭时触发
    editdialogclose2(){
      this.editqueryData.chaxun = ''
      this.editconfirm2 = false
      this.$refs.comboedit2.$refs.myForm.resetValidation();
    },
    // 分页发送变化函数
    changepageSize(val){
      this.queryData.pageSize = val
      // this.pagination.rowsPerPage = val
      this.getcombolist()
    },
    changePage(val){
      this.queryData.page = val
      this.getcombolist()
    }
  },
};
</script>

<style lang='less' scoped>
#Combo{
  height: 100%;
}
.combobox{
  height: 90%;
}
.searchbox{
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  .search{
    display: flex;
    flex-direction: row ;
    align-items: flex-end;
    // margin-bottom: 1.25rem;
    .operation{
      height: 2.25rem;
      width: 90%;
      margin: .625rem;
    }
  }
}

.el-button{
  background: #2196f3 !important;
  height: 2.25rem;
  color: #fff;
  margin: 0 .625rem .625rem .625rem;
}
.addbtn{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  .el-button{
    background: #2196f3 !important;
    height: 2.25rem;
    color: #fff;
    margin: 0 .625rem .625rem .625rem;
  }
}
 .czbtn{
    margin-right: .75rem;
  }
/deep/.my-special-class{
  font-size: 14px;
  font-weight: bold;
  color: #909399;
}
.Paginationstyle{
  margin-top: .9375rem;
}
// style="width: 13.5625rem;" 搜索框专用
</style>
