<template>
  <div>
      <q-form ref="myForm" greedy class="q-gutter-md add">
        <div class='maxgao'>
          <div class="row">
            <!-- 左边 -->
            <div class="col combostyle">
              <q-input 
                style='margin-top: 0;' 
                :dense='true' 
                outlined  
                v-model="editDatas.comboName" 
                label="套餐名称*" 
                lazy-rules 
                :rules="[required.comboName,required.comboNamelength]"
              />
              <!-- 回款金额 -->
              <q-input 
                mask="#####"
                style='margin-top: 0;'
                :dense='true'
                outlined
                v-model="editDatas.returnedAmt"
                label="回款金额*"
                lazy-rules
                :rules="[required.returnedLimit,required.returnedLessthan]"
              />
            </div>
            <!-- 右边 -->
            <div class="col combostyle">
              <q-input 
                mask="#####"
                style='margin-top: 0;' 
                :dense='true' 
                outlined  
                v-model="editDatas.comboAmt" 
                label="套餐金额*" 
                lazy-rules 
                :rules="[required.limit]"
              />
              <!-- 加急金额 -->
              <q-input 
                mask="#####"
                style='margin-top: 0;' 
                :dense='true' 
                outlined  
                v-model="editDatas.urgentAmt" 
                label="加急金额" 
                lazy-rules 
                :rules="[required.urgentLimit]"
              />
            </div>
          </div>
            <q-input 
              style='margin-top: 0;' 
              class="combostyle" 
              :dense='true' 
              outlined  
              autogrow
              type="textarea"
              v-model="editDatas.comboDesc" 
              label="套餐描述*" 
              lazy-rules 
              :rules="[required.comboDesc,required.comboDesclength]"
            />
            
          <!-- 切换区域 -->
          <el-tabs  v-model="activeName" @tab-click="handleClick" :stretch='true'>
            <!-- 自定义金额 -->
            <el-tab-pane label="自定义金额" name="first">
              <!-- 自定义金额区域 
                addDeparts: 点击自定义金额函数
                delhosp: 移除自定义金额
                edit: 编辑自定义金额
                qingchu: 清除搜索框
                search: 搜索自定义金额
              -->
              <comboAmtTabel
                ref="comboAmtTabel"
                :addqueryData.sync='addqueryDatas'
                @addDeparts='addDeparts'
                @delhosp='delhosp'
                @edit='editzhidingHosp'
                @qingchu='qingchuzdHosp'
                @search='getcombohosp'
              />
              <!-- 自定义金额区域结束 -->
            </el-tab-pane>
            <!-- 指定医院 
              addDeparts: 指定医院
              delhosp: 移除指定医院
              qingchu: 清除搜索框
              search: 搜索指定医院
            -->
            <el-tab-pane label="指定医院" name="second">
              <appointhospTabel
                ref="appointhospTabel"
                :addqueryData.sync='addAppointDatas'
                @addDeparts='openAppoint'
                @delhosp='delAppointhosp'
                @qingchu='qingchuAppointdHosp'
                @search='getAppointdhosp'
              />
            </el-tab-pane>
            <!-- 屏蔽医院 -->
            <el-tab-pane label="屏蔽医院" name="third">
              <shieldhospTabel
                ref="shieldhospTabel"
                :addqueryData.sync='addShieldDatas'
                @addDeparts='openShield'
                @delhosp='delShieldHosp'
                @qingchu='qingchuShieldHosp'
                @search='getShieldhosp'
              />
            </el-tab-pane>
          </el-tabs>
        </div>
          

        <div class="addbtn">
          <q-btn
              class="kong"
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          />
          <q-btn
              label="保存"
              color="blue-6"
              @click="editHospList"
          />
        </div>
      </q-form>
      
      <!-- 自定义金额弹窗 -->
      <customamount
        ref="customamount"
        :departAdd.sync='departAdd'
        :addqueryData='addqueryDatas'
        :appoint='appointdata'
        @okadd='okadd'
        @closeadddepart='closeadddepart'
      />
      <!-- 编辑自定义金额弹窗 -->
      <editcustomamount
        ref="editcustomamount"
        :departEdit.sync='departEdit'
        :edithosp.sync='edithosp'
        :editData.sync='editDatas'
        @okedit='okedit'
      />

      <!-- 指定医院弹窗 -->
      <appointhosp
        ref="appointHosp"
        :departAdd.sync='appointconfing'
        :addqueryData.sync='addAppointDatas'
        @okadd='okappoint'
        @closeadddepart='closeappoint'
      />

      <!-- 屏蔽医院弹窗 -->
      <shieldhosp
        ref="shieldhosp"
        :departAdd.sync='shieldconfing'
        :addqueryData.sync='addShieldDatas'
        @okadd='okshield'
        @closeadddepart='closeshield'
      />

  </div>
</template>

<script>
import customamount from '@/components/Combo/components/customamount/index' // 自定义金额
import editcustomamount from '@/components/Combo/components/editcustomamount/index' //修改自定义金额
import appointhosp from '@/components/Combo/components/appointhosp/index' //指定医院
import shieldhosp from '@/components/Combo/components/shieldhosp/index' //指定医院

import comboAmtTabel from '@/components/Combo/Table/comboAmtTabel/index' //自定义金额表格
import appointhospTabel from '@/components/Combo/Table/appointhospTabel/index' //指定医院表格
import shieldhospTabel from '@/components/Combo/Table/shieldhospTabel/index' //屏蔽医院表格

import { provinceList,cityList,hospList } from '@/api/City.js'; // 获取城市接口
import { keepTwoDecimalFull } from '@/api/components/xiaoshudian.js'; //小数点取后两位
import { combohospadd,searchhosp,combohospdel,combohospedit,comboEdit,hospMapList,hospMapAdd,hospMapDel } from '@/api/Combo.js'; // 套餐增删改查接口
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getprovince()
  },
  
  data() {
    return {
        required,
        text:'',

        departAdd: false, //自定义金额控制
        appointdata:{},//自定义金额时 获取的套餐信息

        // 编辑科室弹窗信息
        departEdit: false, //修改自定义金额弹窗控制
        edithosp:{}, // 编辑指定医院所需信息

        appointconfing: false, //控制指定医院

        shieldconfing: false, //控制屏蔽医院

        activeName: 'first',

        tableData: [],

        province:[], //省
        city:[], // 市
        hosplist:[], // 医院

        provincevale: null, // 省份选择id值
        required: {
            kong: ()=>{},
            comboName: val => val && val.trim().length > 0 || '套餐名称不能为空',
            comboNamelength: val => val && val.trim().length <= 20 || '套餐名称长度不能超过20个字符',
            comboDesc: val => val && val.trim().length > 0 || '套餐描述不能为空',
            comboDesclength: val => val && val.trim().length <=80 || '套餐描述长度不能超过80个字符',
            // appointAmt: val => val && val.length > 0 || '套餐金额不能为空',
            limit: val=> val && val>=1 && val<= 9999.99 || '套餐金额在1~9999.99之间',

            returnedLimit: val=> val && val>0 && val<= 9999.99 || '回款金额在1~9999.99之间',
            returnedLessthan: val => {
              if(val - this.editDatas.comboAmt < 0){
                
              }else{
                return '回款金额不能大于套餐金额'
              }
              // val < this.editDatas.comboAmt || '回款金额不能大于套餐金额'
            },
            urgentLimit: val=> val && val>0 && val<= 9999.99 || '加急金额在1~9999.99之间'
        },
    };
  },
  props:{
    editData:{
      type: Object,
      required: true
    },
    addqueryData:{ //自定义金额模块所需信息
      type: Object,
      required: true
    },
    addAppointData:{ //指定医院模块所需信息
      type: Object,
      required: true
    },
    addShieldData:{ //屏蔽医院模块所需信息
      type: Object,
      required: true
    }
  },
  components:{
    customamount, //自定义金额弹出框
    editcustomamount, //编辑自定义金额弹窗

    appointhosp, //指定医院弹窗
    shieldhosp, //屏蔽医院弹窗

    comboAmtTabel, //自定义金额表格
    appointhospTabel, //指定医院表格
    shieldhospTabel, //屏蔽医院表格
  },
  computed:{
    editDatas:{
      get(){
        return this.editData
      },
      set(v){
        this.$emit('update:editData',v)
      }
    },
    // 储存城市信息
    addqueryDatas:{ //自定义金额模块所需信息
      get(){
        return this.addqueryData
      },
      set(v){
        this.$emit('update:addqueryData',v)
      }
    },
    addAppointDatas:{  //指定医院模块所需信息
      get(){
        return this.addAppointData
      },
      set(v){
        this.$emit('update:addAppointData',v)
      }
    },
    addShieldDatas:{  //指定医院模块所需信息
      get(){
        return this.addShieldData
      },
      set(v){
        this.$emit('update:addShieldData',v)
      }
    }
  },
  watch:{
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if(tab.name=='first'){
        console.log('点击了自定义金额模块');
        this.addAppointDatas.chaxun=''
        this.addShieldDatas.chaxun=''
        this.getcombohosp()
      }else if(tab.name=='second'){
        this.addqueryDatas.chaxun=''
        this.addShieldDatas.chaxun=''
        this.getComboMapList(1,this.addAppointDatas.chaxun)
        console.log('点击了指定医院模块');
      }else{
        this.addqueryDatas.chaxun=''
        this.addAppointDatas.chaxun=''
        this.getComboMapList(0,this.addShieldDatas.chaxun)
        console.log('点击了屏蔽医院模块');
      }
    },
    
    // 获取自定义金额列表数据
    async getcombohosp(hospName){
      const { data: res } = await searchhosp({comboId: this.editDatas.id,hospName:hospName})
       this.addqueryDatas.allhosp= res.data.comboCityHospMergeVos
    },
    // 获取关联医院列表数据
    async getComboMapList(type,name){
      //type 输入0 查询屏蔽的医院   输入1查询绑定的医院
      const { data: res } = await hospMapList({
        comboId: this.editDatas.id,
        oprType:type,
        hospName: name,
      })
      console.log(res.data)
      if(res.code===20000){
        if(type===0){
          this.addShieldDatas.allhosp=[]
          console.log('屏蔽医院列表');
          this.addShieldDatas.allhosp= res.data.blackHosps
        }else{
          this.addAppointDatas.allhosp=[]
          console.log('绑定医院列表');
          this.addAppointDatas.allhosp= res.data.whiteHosps
        }
      }
    },
    // 清除自定义金额搜索框内容
    qingchuzdHosp(){
      this.addqueryDatas.chaxun = ''
      this.getcombohosp(this.addqueryDatas.chaxun)
    },
    // 获取省份信息
    async getprovince(){
      const { data:res } = await provinceList()
      console.log(res);
      this.addqueryDatas.province = res.data.provinces
      this.addAppointDatas.province = res.data.provinces
      this.addShieldDatas.province = res.data.provinces
    },
    // // 获取城市
    // // async getcity(provinceid){
    // //   const { data:res } = await cityList({provinceId:provinceid})
    // //   console.log(res);
    // //   this.addqueryDatas.city = res.data.cities
    // // },
    // // 省份选择发生改变时  
    quxiao(){
      this.$emit("editconfirmclose")
    },
    // 完成编辑点击保存时
    async editHospList(){
      this.$q.dialog({
        title: '保存',
        message: '您是否要保存此套餐的信息',
        ok: { label: '确定'},
        cancel:{ label: '取消',flat: true}
      }).onOk( async() => {
        var comboAmt = keepTwoDecimalFull(this.editDatas.comboAmt) //小数点取后两位
        var returnedAmt = keepTwoDecimalFull(this.editDatas.returnedAmt) //小数点取后两位
        var urgentAmt = keepTwoDecimalFull(this.editDatas.urgentAmt) //小数点取后两位
        const { data:res } = await comboEdit({
          comboName: this.editDatas.comboName, //
          comboDesc: this.editDatas.comboDesc, //
          comboAmt, // 套餐金额
          returnedAmt, // 回款金额
          urgentAmt, // 加急金额
          id: this.editDatas.id
        })
        if(res.code===20000){
          this.getcombohosp()
          this.$emit("editconfirmclose") //关闭编辑框
          this.$emit("editcombosuccess") //刷新套餐列表

          notifys('positive',res.message)
        }else{
          notifys('negative',res.message)
        }
      })
    },

    //自定义金额区域开始

    //点击自定义金额 打开指定医院框
    addDeparts(){
      this.appointdata = JSON.parse(JSON.stringify(this.editDatas))
      this.departAdd =true
    },
    // 关闭自定义金额弹窗时触发
    closeadddepart(){
      this.addqueryDatas.provinceid=''
      this.addqueryDatas.cityId=''
      this.addqueryDatas.city=[]
      this.addqueryDatas.hospid=''
      this.addqueryDatas.hosp=[]
      this.addqueryDatas.comboAmt = JSON.parse(JSON.stringify(this.editDatas.comboAmt))
      this.addqueryDatas.returnedAmt = JSON.parse(JSON.stringify(this.editDatas.returnedAmt))
    },
    // 自定义金额确定时
    okadd(){
      var comboAmt = keepTwoDecimalFull(this.addqueryDatas.comboAmt)
      var returnedAmt = keepTwoDecimalFull(this.addqueryDatas.returnedAmt)
      console.log(comboAmt);
      this.$refs.customamount.$refs.appointdepart.validate().then(async outcome =>{
        if(outcome){
          const { data:res } = await combohospadd({
            cityId: this.addqueryDatas.cityId.id,
            comboAmt,
            returnedAmt,
            hospId: this.addqueryDatas.hospid.id,
            comboId: this.editDatas.id,
          })
          if(res.code===20000){
            notifys('positive',res.message)

            this.departAdd =false
            this.getcombohosp()
          }else{
            notifys('negative',res.message)
          }
        }
      })
    },
    // 移除绑定医院触发
    async delhosp(row){
      console.log(row);
      this.$q.dialog({
        title: '移除',
        message: '您是否要移除此医院',
        ok: { label: '确定'},
        cancel:{ label: '取消',flat: true}
      }).onOk( async() => {
        // console.log('OK')
        const { data:res } = await combohospdel({
          cityId: row.cityId, //
          hospId: row.hospId, //
          comboId: row.comboId, //
        })
        if(res.code===20000){
          notifys('positive',res.message)
          this.getcombohosp()
        }else{
          notifys('negative',res.message)
        }
      })
    },
    // 点击已自定义金额的医院编辑时
    editzhidingHosp(row){
      this.edithosp = JSON.parse(JSON.stringify(row))
      console.log(this.edithosp);
      this.departEdit = true
    },
    // 编辑自定义金额的医院保存时
    async okedit(){
      this.$q.dialog({
        title: '保存',
        message: '您是否保存对此医院的修改',
        ok: { label: '确定'},
        cancel:{ label: '取消',flat: true}
      }).onOk( async() => {
        // console.log('OK') editointdepart
        this.$refs.editcustomamount.$refs.editointdepart.validate().then(async outcome =>{
          if(outcome){
            var comboAmt = keepTwoDecimalFull(this.edithosp.comboAmt) //小数点取后两位
            var returnedAmt = keepTwoDecimalFull(this.edithosp.returnedAmt) //小数点取后两位
            const { data:res } = await combohospedit({
              cityId: this.edithosp.cityId,
              comboAmt,
              returnedAmt,
              hospId: this.edithosp.hospId,
              comboId: this.edithosp.comboId,  
            })
            console.log(this.edithosp.comboAmt);
            if(res.code===20000){
              notifys('positive',res.message)
              this.getcombohosp()
              this.departEdit = false
            }else{
              notifys('negative',res.message)
            }
          }
        })
       
      })
      
    },
    //自定义金额区域结束

    /**
     * 
     * 指定医院区域开始
     * 
     */
    // 打开指定医院弹窗
    openAppoint(){
      this.appointconfing = true
    },
    //清空了指定医院搜素框
    qingchuAppointdHosp(){
      this.addAppointDatas.chaxun=''
      this.getComboMapList(1,this.addAppointDatas.chaxun)
      console.log('清空了指定医院搜素框');
    },
    // 获取已经指定医院数据
    getAppointdhosp(){
      this.getComboMapList(1,this.addAppointDatas.chaxun)
      console.log('获取已经指定医院数据');
    },
    // 确定绑定医院时
    okappoint(){
      this.$refs.appointHosp.$refs.appointdepart.validate().then(async outcome =>{
        if(outcome){
          const { data:res } = await hospMapAdd({
            oprType: 1,
            hospId: this.addAppointDatas.hospid.id,
            comboId: this.editDatas.id,
          })
          if(res.code===20000){
            notifys('positive',res.message)
            this.appointconfing =false
            this.getComboMapList(1)
          }else{
            notifys('negative',res.message)
          }
        }
      })
      console.log('确定绑定了医院');
    },
    //移除了指定医院
    delAppointhosp(row){
      this.$q.dialog({
        title: '移除',
        message: '您是否要移除此已经绑定的医院',
        ok: { label: '确定'},
        cancel:{ label: '取消',flat: true}
      }).onOk( async() => {
        // console.log('OK')
        const { data:res } = await hospMapDel({
          id: row.id,
        })
        if(res.code===20000){
          notifys('positive',res.message)
          this.getComboMapList(1)
        }else{
          notifys('negative',res.message)
        }
      })
      console.log('移除了指定医院');
    },
    // 指定医院弹窗关闭时
    closeappoint(){
      this.addAppointDatas.provinceid=''
      this.addAppointDatas.cityId=''
      this.addAppointDatas.city=[]
      this.addAppointDatas.hospid=''
      this.addAppointDatas.hosp=[]
      this.appointconfing = false
    },
    /**
     * 指定医院区域结束
     */


    /**
     * 屏蔽医院模块开始
    */
    // 获取已经屏蔽医院数据
    getShieldhosp(){
      this.getComboMapList(0,this.addShieldDatas.chaxun)
      console.log('获取已经屏蔽医院数据');
    },
    //清空了屏蔽医院搜素框
    qingchuShieldHosp(){
      this.addShieldDatas.chaxun=''
      this.getComboMapList(0,this.addShieldDatas.chaxun)
      console.log('清空了屏蔽医院搜素框');
    },
    // 打开屏蔽医院弹窗
    openShield(){
      this.shieldconfing = true
    },
    //移除了屏蔽医院
    delShieldHosp(row){
      this.$q.dialog({
        title: '移除',
        message: '您是否要移除此已经屏蔽的医院',
        ok: { label: '确定'},
        cancel:{ label: '取消',flat: true}
      }).onOk( async() => {
        // console.log('OK')
        const { data:res } = await hospMapDel({
          id: row.id,
        })
        if(res.code===20000){
          notifys('positive',res.message)
          this.getComboMapList(0)
        }else{
          notifys('negative',res.message)
        }
      })
      console.log('移除了屏蔽医院');
    },
    // 确定屏蔽医院时
    okshield(){
      this.$refs.shieldhosp.$refs.appointdepart.validate().then(async outcome =>{
        if(outcome){
          const { data:res } = await hospMapAdd({
            oprType: 0,
            hospId: this.addShieldDatas.hospid.id,
            comboId: this.editDatas.id,
          })
          if(res.code===20000){
            notifys('positive',res.message)
            this.shieldconfing =false
            this.getComboMapList(0)
          }else{
            notifys('negative',res.message)
          }
        }
      })
      console.log('确定屏蔽了医院');
    },
    // 屏蔽医院弹窗关闭时
    closeshield(){
      this.addShieldDatas.provinceid=''
      this.addShieldDatas.cityId=''
      this.addShieldDatas.city=[]
      this.addShieldDatas.hospid=''
      this.addShieldDatas.hosp=[]
      this.shieldconfing = false
    }

    /**
     * 屏蔽医院模块结束
    */
  },
};
</script>

<style lang='less' scoped>
.shieldhosp{
  margin-top: .625rem;
}
.adddepart{
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}
.departtop{
  margin: .625rem 0;
}
.kong{
  margin: 0 .625rem !important;
}
.operation{
    width: 100%;
    margin-top: 0;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
.combostyle{
  padding: 0 5px;
}
.maxgao{
  max-height: 50vh;
  overflow: auto;
}
</style>
