export const keepTwoDecimalFull= (num)=>{
    var result = parseFloat(num);  
    if (isNaN(result)) {  
        // alert('传递参数错误，请检查！');  
        return false;  
    }  
    // result = Math.round(num * 100) / 100;  
    result = Math.floor(num);  
    console.log(result);
    var s_x = result.toString(); //将数字转换为字符串

    var pos_decimal = s_x.indexOf('.'); //小数点的索引值


    // // 当整数时，pos_decimal=-1 自动补0  
    // if (pos_decimal < 0) {  
    //     console.log('找不到小数点',s_x);

    //     // pos_decimal = s_x.length;  
    //     // s_x += '.';  
    // }

    // // 当数字的长度< 小数点索引+2时，补0  
    // while (s_x.length <= pos_decimal + 2) {
    //     console.log(s_x);
    //     s_x += '0';  
    // }  
    return s_x;  
}