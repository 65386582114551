<template>
    <!-- 指定医院区域 -->
    <q-card flat bordered class="my-card">
        <q-card-section>
            <div>
            <!-- 搜索指定医院区域 -->
            <div class="adddepart">
                <q-input outlined :dense="true" v-model="addqueryDatas.chaxun" label="请输入医院名称搜索">
                <template v-slot:append>
                    <q-icon name="close" v-if="addqueryDatas.chaxun" @click.stop="qingchu" class="cursor-pointer" style="font-size: 16px;"/>
                    <q-icon name="search" @click='search'/>
                </template>
                </q-input>

                <q-btn color="blue-6" label="自定义金额" @click='addDeparts'/>
            </div>
            <!-- 自定义金额列表区域  max-height="250"-->
            <div>
                <el-table
                :data="addqueryDatas.allhosp"
                style="width: 100%"
                >
                <el-table-column
                fixed
                prop="cityName"
                label="城市">
                </el-table-column>
                <el-table-column
                fixed
                prop="hospName"
                label="医院">
                </el-table-column>
                <el-table-column
                fixed
                prop="comboAmt"
                label="套餐金额">
                </el-table-column>
                <el-table-column
                fixed
                prop="returnedAmt"
                label="回款金额">
                </el-table-column>
                <el-table-column
                align='center'
                label="操作"
                width="120">
                <template slot-scope="anniu">
                    <el-button
                    type="text"
                    size="small"
                    @click='delhosp(anniu.row)'
                    >
                    移除
                    </el-button>
                    <el-button
                    type="text"
                    size="small"
                    @click='edit(anniu.row)'
                    >
                    编辑
                    </el-button>
                </template>
                </el-table-column>
            </el-table>
            </div>
            </div>
        </q-card-section>
    </q-card>
    <!-- 指定医院区域结束 -->
</template>
<script>
export default {
    props:{
        addqueryData: { //表格所需信息
            type: Object,
            required: true,
        },
    },
    data() {
        return {

        }
    },
    created(){
    },
    computed:{
        addqueryDatas:{
            get(){
                return this.addqueryData
            },
            set(v){
                this.$emit('update:addqueryData',v)
            }
        },
    },
    methods:{
        // 点击自定义金额
        addDeparts(){
            this.$emit('addDeparts')
        },
        delhosp(rows){
            console.log(rows);
            this.$emit('delhosp', rows)
        },
        edit(rows){
            this.$emit('edit', rows)
        },
        qingchu(){
            this.$emit('qingchu')
        },
        search(){
            this.$emit('search',this.addqueryDatas.chaxun)
        }
    },
}
</script>
<style lang="less" scoped>
.shieldhosp{
  margin-top: .625rem;
}
.adddepart{
  display: flex;
  justify-content: space-between;
  margin-top: 0;
}
.departtop{
  margin: .625rem 0;
}
.kong{
  margin: 0 .625rem !important;
}
.operation{
    width: 100%;
    margin-top: 0;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
</style>
