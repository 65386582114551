<template>
    <!-- 编辑指定科室弹窗 -->
    <q-dialog v-model="departEdits" persistent>
        <q-card style="min-width: 550px">
          <q-card-section>
            <div class="text-h6">编辑科室</div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div class='departtop'>
              <q-form ref="editointdepart" greedy class="q-gutter-md add">
                <!-- <q-input outlined :dense="true" v-model="edithosp.departName" label="省份" /> -->
                <q-input outlined disable :dense="true" v-model="edithosps.cityName" label="城市" :rules="[required.kong]" />
                <q-input outlined disable :dense="true" v-model="edithosps.hospName" label="医院" :rules="[required.kong]" />
                <q-input outlined disable :dense="true" v-model="editDatas.comboName" label="套餐名称" :rules="[required.kong]" />
                <q-input outlined mask="#####" :dense="true" v-model="edithosps.comboAmt" label="金额" :rules="[required.limit]" />
                <q-input outlined mask="#####" :dense="true" v-model="edithosps.returnedAmt" label="回款金额*" :rules="[required.returnedLimit,required.returnedLessthan]" />
              </q-form>
              
            </div>
            <!-- <div class='departtop'>
              <q-input outlined :dense="true" v-model="editdepart.departDesc" label="请输入科室描述" />
            </div> -->
          </q-card-section>

          <q-card-actions align="right">
            <q-btn flat label="取消" color="primary" v-close-popup/>
            <q-btn label="保存" color="primary"  @click='okedit'/>
          </q-card-actions>
        </q-card>
    </q-dialog>
</template>
<script>
export default {
    props:{
        departEdit: { //控制编辑自定义金额
            type: Boolean,
            required: true,
        },
        edithosp: { //控制指定医院
            type: Object,
            required: true,
        },
        editData: { //控制指定医院
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            required: {
                kong: ()=>{},
                comboName: val => val && val.trim().length > 0 || '套餐名称不能为空',
                comboNamelength: val => val && val.trim().length <= 20 || '套餐名称长度不能超过20个字符',
                comboDesc: val => val && val.trim().length > 0 || '套餐描述不能为空',
                comboDesclength: val => val && val.trim().length <=80 || '套餐描述长度不能超过80个字符',
                // appointAmt: val => val && val.length > 0 || '套餐金额不能为空',
                limit: val=> val && val>0 && val<= 9999.99 || '套餐金额必须在1~9999.99之间',
                // returnedAmt: val => val && val.trim().length > 0 || '回款金额不能为空',
                returnedLimit: val=> val && val>0 && val<= 9999.99 || '回款金额必须在1~9999.99之间',
                returnedLessthan: val => {
                    if(val - this.edithosps.comboAmt < 0){
                        
                    }else{
                        return '回款金额不能大于套餐金额'
                    }
                    // val < this.edithosps.comboAmt || '回款金额不能大于套餐金额'
                },
            },
        }
    },
    created(){
    },
    components:{
        
    },
    computed:{
        departEdits:{
            get(){
                return this.departEdit
            },
            set(v){
                this.$emit('update:departEdit',v)
            }
        },
        edithosps:{
            get(){
                return this.edithosp
            },
            set(v){
                this.$emit('update:edithosp',v)
            }
        },
        editDatas:{
            get(){
                return this.editData
            },
            set(v){
                this.$emit('update:editData',v)
            }
        },
    },
    methods:{
        okedit(){
            this.$emit('okedit')
        }
    },
}
</script>
<style lang="less" scoped>

</style>
