<template>
  <div>
      <q-form ref="myForm" greedy class="q-gutter-md add">
        <div class="row">
          <div class="col combostyle">
            <q-input 
              style='margin-top: 0;' 
              :dense='true' 
              outlined  
              v-model="addqueryDatas.comboName" 
              label="套餐名称*" 
              lazy-rules 
              :rules="[required.comboName,required.comboNamelength]"
            />
            <!-- 回款金额 -->
            <!-- mask="#####" -->
            <q-input 
              ref='returnedAmt'
              mask="#####"
              style='margin-top: 0;' 
              :dense='true' 
              outlined  
              v-model="addqueryDatas.returnedAmt" 
              label="回款金额*" 
              lazy-rules 
              :rules="[required.returnedAmt,required.returnedLimit,required.returnedLessthan]"
            />
          </div>
          <div class="col combostyle">
            <!-- mask="#####" -->
            <q-input 
              style='margin-top: 0;' 
              mask="#####"
              :dense='true' 
              outlined  
              v-model="addqueryDatas.comboAmt" 
              label="套餐金额*" 
              lazy-rules 
              :rules="[required.comboAmt,required.limit]"
            />
            <!-- 加急金额 -->
            <!-- mask="#####" -->
            <q-input 
              style='margin-top: 0;' 
              mask="#####"
              :dense='true' 
              outlined  
              v-model="addqueryDatas.urgentAmt" 
              label="加急金额*" 
              lazy-rules 
              :rules="[required.urgentAmt,required.urgentLimit]"
            />
          </div>
        </div>
        <!-- 套餐描述 -->
        <q-input 
          class="combostyle"
          style='margin-top: 0;' 
          :dense='true' 
          outlined  
          autogrow
          type="textarea"
          v-model="addqueryDatas.comboDesc" 
          label="套餐描述*" 
          lazy-rules 
          :rules="[required.comboDesc,required.comboDesclength]"
        />

        <div class="addbtn">
          <el-popover
            placement="bottom"
            width="155"
            v-model="visible">
            <p>未保存的数据将被丢弃, 是否继续?</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
            </div>
            <el-button  type="text" slot="reference">取消</el-button>
          </el-popover>
          <!-- <q-btn
              flat
              label="取消"
              color="primary"
              v-close-popup
              @click="quxiao"
          /> -->
          <q-btn
              class="kong"
              label="添加"
              color="blue-6"
              @click="addComboList"
          />
        </div>
      </q-form>
  </div>
</template>

<script>
// import { provinceList,cityList,hospList } from '@/api/City.js'; // 获取城市接口
import { comboAdd } from '@/api/Combo.js'; // 套餐增删改查接口
import { keepTwoDecimalFull } from '@/api/components/xiaoshudian.js'; //小数点取后两位
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
  },
  
  data() {
    return {
        visible: false,//控制取消提示

        province:[], //省
        city:[], // 市
        hosplist:[], // 医院

        provincevale: null, // 省份选择id值
        returnedLessthan: true,
        required: {
          kong: ()=>{},
          comboName: val => val && val.trim().length > 0 || '套餐名称不能为空',
          comboNamelength: val => val && val.trim().length <= 20 || '套餐名称长度不能超过20个字符',
          comboDesc: val => val && val.trim().length > 0 || '套餐描述不能为空',
          comboDesclength: val => val && val.trim().length <=80 || '套餐描述长度不能超过80个字符',
          comboAmt: val => val && val.trim().length > 0 || '套餐金额不能为空',
          limit: val=> val && val>0 && val<= 9999.99 || '套餐金额必须在1~9999.99之间',

          returnedAmt: val => val && val.trim().length > 0 || '回款金额不能为空',
          returnedLessthan: val => {
            if(val - this.addqueryDatas.comboAmt < 0){
              
            }else{
              return '回款金额不能大于套餐金额'
            }
            // val < this.addqueryDatas.comboAmt || '回款金额不能大于套餐金额'
          },
          returnedLimit: val=> val && val>0 && val<= 9999.99 || '回款金额必须在1~9999.99之间',

          urgentAmt: val => val && val.trim().length > 0 || '加急金额不能为空',
          urgentLimit: val=> val && val>0 && val<= 9999.99 || '加急金额必须在1~9999.99之间'
    
        },
    };
  },
  props:{
    addqueryData:{
      type: Object,
      required: true
    }
  },
  components:{
    
  },
  computed:{
    addqueryDatas:{
      get(){
        return this.addqueryData
      },
      set(v){
        this.$emit('update:addqueryData',v)
      }
    }
  },
  watch:{
  },
  methods: {
    returnedAmtInput(v){
      console.log(v);
      if(v >= this.addqueryDatas.comboAmt){
        this.returnedLessthan = false
      }else{
        this.returnedLessthan = true
      }
    },
    quxiao(){
      this.visible = false
      this.$emit("addconfirmclose")
    },
    addComboList(){
      var comboAmt = keepTwoDecimalFull(this.addqueryDatas.comboAmt) //小数点取后两位 套餐金额
      var returnedAmt = keepTwoDecimalFull(this.addqueryDatas.returnedAmt) //小数点取后两位 回款金额
      var urgentAmt = keepTwoDecimalFull(this.addqueryDatas.urgentAmt) //小数点取后两位 加急金额

      // var comboAmt = this.addqueryDatas.comboAmt//小数点取后两位 套餐金额
      // var returnedAmt = this.addqueryDatas.returnedAmt //小数点取后两位 回款金额
      // var urgentAmt = this.addqueryDatas.urgentAmt //小数点取后两位 加急金额

      this.$refs.myForm.validate().then( async outcome =>{
        if (outcome){
          try{
            const { data:res } = await comboAdd({
              comboName: this.addqueryDatas.comboName,
              comboDesc: this.addqueryDatas.comboDesc,
              comboAmt,
              returnedAmt,
              urgentAmt,
            })
            console.log(res);
            if(res.code===20000){
              this.provincevale=null
              this.$emit('addcombosuccess') // 表格刷新页面
              this.$emit('addconfirmclose') // 清空表单内容

              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (err) {
            // console.log(err);
          }
        }
      })
    }
  },
};
</script>

<style lang='less' scoped>
.operation{
    width: 100%;
    margin-top: 0;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
.kong{
  margin-left: 1.25rem !important;
}
.combostyle{
  padding: 0 5px;
}
</style>
